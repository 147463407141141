import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Avatar,
  TextField,
  Button,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserDetails, setUserDetails } from '../../../store/usersSlices';
import { useNavigate } from 'react-router-dom';
import { get, ref, child, update } from 'firebase/database';
import { getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage';
import { auth, realtimeDB, storage } from '../../../firebase/config';

const MeuPerfil = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector(selectUserDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // Para controlar o ProgressBar durante o salvamento
  const [profileData, setProfileData] = useState({
    nome: userDetails?.nome || '',
    telefone: userDetails?.telefone || '',
    cpf: userDetails?.cpf || '',
    dataNascimento: userDetails?.dataNascimento || '',
    estadoCivil: userDetails?.estadoCivil || '',
    endereco: userDetails?.endereco || '',
    numero: userDetails?.numero || '',
    complemento: userDetails?.complemento || '',
    bairro: userDetails?.bairro || '',
    cidade: userDetails?.cidade || '',
    estado: userDetails?.estado || '',
    photoURL: userDetails?.photoURL || '/default-avatar.png',
  });
  const [newPhoto, setNewPhoto] = useState(null); // Armazena a nova foto selecionada

  useEffect(() => {
    if (!userDetails || Object.keys(userDetails).length === 0) {
      const fetchUserDetails = async () => {
        try {
          setIsLoading(true);
          const userId = auth.currentUser.uid;
          const userRef = ref(realtimeDB, `users/${userId}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            dispatch(setUserDetails(userData));
            setProfileData(userData);
          } else {
            navigate('/entrar');
          }
        } catch (error) {
          console.error('Erro ao buscar os detalhes do usuário:', error);
          navigate('/entrar');
        } finally {
          setIsLoading(false);
        }
      };

      fetchUserDetails();
    }
  }, [dispatch, navigate, userDetails]);

  const handleEditToggle = () => {
    setIsEditing(true); // Habilita modo de edição
  };

  // Função de salvar perfil
  const handleSaveProfile = async () => {
    setIsSaving(true); // Mostra o ProgressBar ao salvar
    try {
      const userId = auth.currentUser.uid;

      // Atualiza os dados do perfil no Realtime Database
      const userRef = ref(realtimeDB, `users/${userId}`);
      await update(userRef, profileData);

      // Se houver uma nova foto selecionada, faz o upload da foto
      if (newPhoto) {
        const imageRef = storageRef(storage, `user/profileimagens/${userId}`);
        await uploadBytes(imageRef, newPhoto); // Faz o upload da nova foto
        const photoURL = await getDownloadURL(imageRef); // Obtém o URL da nova foto
        setProfileData((prevData) => ({ ...prevData, photoURL }));

        // Atualiza o `photoURL` no Realtime Database e no Auth
        await update(userRef, { photoURL }); // Atualiza o campo `photoURL`
        auth.currentUser.updateProfile({ photoURL }); // Atualiza o perfil no Firebase Auth
      }

      // Atualiza o Redux com os dados atualizados
      dispatch(setUserDetails(profileData));
      setIsEditing(false); // Desabilita modo de edição
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
    } finally {
      setIsSaving(false); // Oculta o ProgressBar
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewPhoto(file); // Armazena a nova foto para o upload
      const reader = new FileReader();
      reader.onload = () => {
        setProfileData((prevData) => ({ ...prevData, photoURL: reader.result })); // Atualiza a pré-visualização da foto
      };
      reader.readAsDataURL(file);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 5,
        p: 3,
      }}
    >
      <Paper elevation={3} sx={{ p: 3, maxWidth: 800, width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Avatar
            alt={profileData.nome}
            src={profileData.photoURL}
            sx={{ width: 100, height: 100 }}
          />
        </Box>

        {/* Botão para Trocar a Foto de Perfil */}
        {isEditing && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <label htmlFor="upload-photo">
              <input
                style={{ display: 'none' }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                accept="image/*"
                onChange={handlePhotoChange}
              />
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<PhotoCamera />}
              >
                Trocar Foto
              </Button>
            </label>
          </Box>
        )}

        <Typography variant="h5" align="center" gutterBottom>
          Perfil do Usuário
        </Typography>

        <Box component="form">
          {/* Painel de Dados Pessoais */}
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Dados Pessoais</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Nome"
                    name="nome"
                    fullWidth
                    value={profileData.nome}
                    onChange={handleChange}
                    InputProps={{ readOnly: !isEditing }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Telefone"
                    name="telefone"
                    fullWidth
                    value={profileData.telefone}
                    onChange={handleChange}
                    InputProps={{ readOnly: !isEditing }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="CPF"
                    name="cpf"
                    fullWidth
                    value={profileData.cpf}
                    InputProps={{ readOnly: true }} // CPF sempre desabilitado
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Data de Nascimento"
                    name="dataNascimento"
                    type="date"
                    fullWidth
                    value={profileData.dataNascimento}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ readOnly: !isEditing }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Estado Civil"
                    name="estadoCivil"
                    fullWidth
                    value={profileData.estadoCivil}
                    onChange={handleChange}
                    InputProps={{ readOnly: !isEditing }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Painel de Endereço */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Endereço</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Endereço"
                    name="endereco"
                    fullWidth
                    value={profileData.endereco}
                    onChange={handleChange}
                    InputProps={{ readOnly: !isEditing }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Número"
                    name="numero"
                    fullWidth
                    value={profileData.numero}
                    onChange={handleChange}
                    InputProps={{ readOnly: !isEditing }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Complemento"
                    name="complemento"
                    fullWidth
                    value={profileData.complemento}
                    onChange={handleChange}
                    InputProps={{ readOnly: !isEditing }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Bairro"
                    name="bairro"
                    fullWidth
                    value={profileData.bairro}
                    onChange={handleChange}
                    InputProps={{ readOnly: !isEditing }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Cidade"
                    name="cidade"
                    fullWidth
                    value={profileData.cidade}
                    onChange={handleChange}
                    InputProps={{ readOnly: !isEditing }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Estado"
                    name="estado"
                    fullWidth
                    value={profileData.estado}
                    onChange={handleChange}
                    InputProps={{ readOnly: !isEditing }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Botão de Editar/Salvar */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            {isEditing ? (
              <>
                {isSaving && <LinearProgress sx={{ width: '100%', mb: 2 }} />}
                {
                  !isSaving &&  <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveProfile}
                  disabled={isSaving} // Desabilita o botão durante o salvamento
                >
                  Salvar Alterações
                </Button>
                }     
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={handleEditToggle}
              >
                Editar
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default MeuPerfil;
