import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { auth, realtimeDB } from "./../firebase/config";
import { ref, set, get, child } from "firebase/database"; // Realtime Database
import { signOut, signInWithCustomToken, createUserWithEmailAndPassword, updateProfile, getIdToken } from "firebase/auth";

// AsyncThunk para registrar o usuário no Firebase Auth e salvar dados no Realtime Database
export const cadastrarBeneficiario = createAsyncThunk(
  "users/cadastrarBeneficiario",
  async (userData, { rejectWithValue }) => {
    const { email, senha, nome, cpf, telefone, endereco, role = 'Beneficiário', ...otherDetails } = userData;

    try {
      // 1. Verifica se o CPF já está cadastrado no Realtime Database
      const dbRef = ref(realtimeDB);
      const snapshot = await get(child(dbRef, `cpfs/${cpf}`)); // Verifica na chave `cpfs` se o CPF já existe
      if (snapshot.exists()) {
        return rejectWithValue("CPF já cadastrado.");
      }

      let user = null;

      // 2. Salva o token da sessão atual (antes de criar o novo usuário)
      const currentUser = auth.currentUser;
      const currentUserToken = await getIdToken(currentUser);

      // 3. Registro no Firebase Auth se o role for 'Editor' ou 'Administrador'
      if (role === 'Editor' || role === 'Administrador') {
        const userCredential = await createUserWithEmailAndPassword(auth, email, senha);
        user = userCredential.user;

        // Atualizar o perfil do novo usuário no Auth (nome, telefone) para Editor e Administrador
        await updateProfile(user, {
          displayName: nome,
          phoneNumber: telefone,
        });
      } else {
        // Se for Beneficiário, criar um UID local sem usar o Firebase Auth
        user = { uid: `beneficiary-${cpf}`, email };
      }

      // 4. Grava dados no Realtime Database
      const userRef = ref(realtimeDB, `users/${user.uid}`);
      await set(userRef, {
        uid: user.uid,
        email: user.email,
        nome: nome,
        cpf: cpf,
        telefone: telefone,
        endereco: endereco,
        role, // Adiciona o nível de acesso (Beneficiário, Editor ou Administrador)
        ...otherDetails, // Outros dados adicionais (ex.: data de nascimento, profissão, etc.)
      });

      // 5. Gravar o CPF em uma chave separada para validação futura, com o role
      const cpfRef = ref(realtimeDB, `cpfs/${cpf}`);
      await set(cpfRef, { uid: user.uid, role });

      // 6. Restaurar a sessão do usuário original
      await signOut(auth);  // Deslogar o novo usuário que foi criado
      await signInWithCustomToken(auth, currentUserToken);  // Restaurar a sessão original com o token salvo

      // Retorna os dados do novo usuário para o estado Redux
      return {
        uid: user.uid,
        email: user.email,
        nome: nome,
        telefone: telefone,
        cpf: cpf,
        endereco: endereco,
        role, // Role será adicionado ao Redux também
        ...otherDetails,
      };
    } catch (error) {
      // Tratamento mais detalhado de erros do Firebase Auth
      if (error.code === "auth/email-already-in-use") {
        return rejectWithValue("O email já está em uso.");
      }
      if (error.code === "auth/weak-password") {
        return rejectWithValue("A senha é muito fraca.");
      }
      if (error.code === "auth/invalid-email") {
        return rejectWithValue("Email inválido.");
      }
      // Rejeita a promise com a mensagem de erro
      return rejectWithValue(error.message);
    }
  }
);

// AsyncThunk para buscar os detalhes do usuário no Realtime Database
export const fetchUserDetails = createAsyncThunk(
  "users/fetchUserDetails",
  async (_, { rejectWithValue }) => {
    const currentUser = auth.currentUser;
    if (!currentUser) return rejectWithValue("Usuário não autenticado.");
    try {
      // 1. Pega o UID do Firebase Auth
      const { uid } = currentUser;

      // 2. Busca os detalhes do usuário no Realtime Database usando o UID
      const dbRef = ref(realtimeDB);
      const snapshot = await get(child(dbRef, `users/${uid}`));

      if (!snapshot.exists()) {
        return rejectWithValue("Detalhes do usuário não encontrados.");
      }

      const userDetailsFromDB = snapshot.val();

      // 3. Combina os dados do Realtime Database com o photoURL do Firebase Auth
      const fullUserDetails = {
        ...userDetailsFromDB,   // Detalhes do Realtime Database (ex: nome, telefone, endereço, etc.)              
      };

      return fullUserDetails;
    } catch (error) {
      return rejectWithValue("Erro ao buscar detalhes do usuário.");
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    currentUser: null, // Usuário atualmente logado
    userDetails: {}, // Detalhes do usuário logado
    status: "idle", // Estado de carregamento (idle, loading, succeeded, failed)
    error: null, // Armazena mensagem de erro
  },
  reducers: {
    setUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    logout: (state) => {  
      state.currentUser = null; // Limpa o usuário logado
      state.userDetails = null; // Limpa detalhes do usuário
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cadastrarBeneficiario.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(cadastrarBeneficiario.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(cadastrarBeneficiario.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Erro no cadastro";
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userDetails = action.payload;  // Define os detalhes do usuário com os dados do Realtime DB e Auth
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Erro ao buscar detalhes do usuário";
      });
  },
});

// Exporta as ações
export const { setUser, logout, setUserDetails } = usersSlice.actions;

// Seletores (acessam o estado dentro de `users`)
export const selectCurrentUser = (state) => state.users.currentUser;
export const selectUserDetails = (state) => state.users.userDetails || {}; // Garante que seja um objeto vazio se for undefined
export const selectUserStatus = (state) => state.users.status;
export const selectUserError = (state) => state.users.error;

// Exporta o reducer
export default usersSlice.reducer;
