import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import DefaultLayout from "./components/DefaultLayout";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/config";
import { setUser, fetchUserDetails, setUserDetails } from "./store/usersSlices";
import { useNavigate } from "react-router-dom";
import CadastroBeneficiario from "./components/CadastroBeneficiario.js";
import { LinearProgress, Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const [routerEnable, setRouterEnable] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true); // Controla o estado de carregamento
  const location = useLocation();

  useEffect(() => {
    // Verifica o estado de autenticação do Firebase
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((token) => {
          dispatch(setUser({ id: user.uid, email: user.email, token: token }));
          dispatch(fetchUserDetails()); // Dispara a ação para buscar os detalhes do usuário
          setRouterEnable(true);         
          if (location.pathname === "/entrar" || location.pathname === "/") {
            navigate("/municipios");
          } else {
            navigate(location.pathname);
          }
        });
      } else {
        dispatch(setUser(null));
        dispatch(setUserDetails(null));
        setRouterEnable(false);
        if (location.pathname === "/cadastrobeneficiario" || location.pathname === "/recuperarsenha") {
          navigate(location.pathname);
        } else {
          navigate("/entrar");
        }    
      }
      setIsLoading(false); // Carregamento completo após autenticação
    });

    // Limpa a inscrição no onAuthStateChanged ao desmontar o componente
    return () => unsubscribe();
  }, [dispatch, navigate]);

  // Exibe uma tela de carregamento enquanto a autenticação está sendo verificada
  if (isLoading) {
    return (
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Carregando...
          </Typography>
          <Box sx={{ width: "80%" }}>
            <LinearProgress />
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/entrar" element={<Login />} />
        <Route path="/recuperarsenha" element={<ResetPassword />} />
        <Route
          path="/cadastrobeneficiario"
          element={<CadastroBeneficiario />}
        />
        <Route path="/home" element={<DefaultLayout />} />
        <Route
          path="*"
          element={routerEnable ? <DefaultLayout /> : <Login />}
        />
      </Routes>
    </div>
  );
}

export default App;
