import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './store/usersSlices.js';
import mapsReducer from './store/mapsSlices.js';
import currentMapReducer from './store/currentMapSlices.js';

export default configureStore({
  reducer: {
    users: usersReducer,
    maps: mapsReducer,
    currentMap: currentMapReducer,
  },
})