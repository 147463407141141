import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import LockIcon from "@mui/icons-material/Lock";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from 'react-redux';
import { cadastrarBeneficiario, selectUserError, selectUserStatus } from '../store/usersSlices';
import axios from "axios";
import { useNavigate } from "react-router-dom";  // Para redirecionamento

// Cidades permitidas
const cidadesPermitidas = [
  { nome: "Catuji", estado: "MG" },
  { nome: "Caraí", estado: "MG" },
  { nome: "Itaipé", estado: "MG" },
  { nome: "Padre Paraíso", estado: "MG" },
  { nome: "Novo Oriente", estado: "MG" },
  { nome: "Ouro Verde", estado: "MG" },
];

// Opções de Estado Civil
const estadoCivilOptions = [
  "Solteiro(a)",
  "Casado(a)",
  "Divorciado(a)",
  "Viúvo(a)",
];

const CadastroBeneficiario = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectUserError);
  const status = useSelector(selectUserStatus);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    cpf: "",
    nome: "",
    dataNascimento: "",
    estadoCivil: "",
    profissao: "",
    rg: "",
    orgaoEmissor: "",
    telefone: "",
    email: "",
    senha: "",
    nomePai: "",
    nomeMae: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
  });

  const [cpfError, setCpfError] = useState("");
  const [cepError, setCepError] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);  // Controle de diálogo de sucesso

  // Função para atualizar os dados no formulário
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCpfChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número
    setFormData({ ...formData, cpf: value });
  };

  // Validação de CPF
  const validarCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
    let soma = 0;
    let resto;
    for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    return resto === parseInt(cpf.substring(10, 11));
  };

  // Função para verificar CPF ao perder o foco
  const handleCPFBlur = () => {
    const isValid = validarCPF(formData.cpf);
    if (!isValid) setCpfError("CPF inválido.");
    else setCpfError("");
  };

  // Busca de CEP
  const handleCepSearch = async () => {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${formData.cep}/json/`);
      if (response.data.erro) {
        setCepError("CEP não encontrado!");
        return;
      }

      const cidadeEncontrada = cidadesPermitidas.find(
        (cidade) => cidade.nome === response.data.localidade && cidade.estado === response.data.uf
      );

      if (!cidadeEncontrada) {
        setCepError("Somente CEPs das cidades permitidas são aceitos.");
        return;
      }

      setFormData({
        ...formData,
        endereco: response.data.logradouro,
        complemento: response.data.complemento,
        bairro: response.data.bairro,
        cidade: response.data.localidade,
        estado: response.data.uf,
      });
      setCepError("");
    } catch (error) {
      setCepError("Erro ao buscar o CEP. Tente novamente.");
      console.error("Erro ao buscar o CEP:", error);
    }
  };

  // Submissão do formulário
  const handleSubmit = (e) => {
    e.preventDefault();
    if (cpfError || cepError) {
      alert("Corrija os erros antes de submeter.");
      return;
    }
    dispatch(cadastrarBeneficiario(formData)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {     
        setSuccessDialogOpen(true);
      }
    });
  };

  // Redireciona para a página de login após o sucesso
  const handleNavigateToLogin = () => {
    setSuccessDialogOpen(false);
    navigate("/");  // Redireciona para a página de login
  };

  return (
    <Box sx={{ mt: 5, mb: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ width: "100%", maxWidth: 600, bgcolor: "background.paper", p: 4, borderRadius: 2, boxShadow: 3 }}>
        <img src="img/logo.png" alt="Logo" width={"60%"} />
        <Typography variant="h4" align="center" gutterBottom>
          Cadastro de Beneficiário
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          {/* Painel de Dados Pessoais */}
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <PersonIcon sx={{ mr: 1 }} />
              <Typography variant="h6">Dados Pessoais</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="CPF"
                    name="cpf"
                    value={formData.cpf}
                    onChange={handleCpfChange}
                    onBlur={handleCPFBlur}
                    error={!!cpfError}
                    helperText={cpfError}
                    inputProps={{ maxLength: 11 }} // Limita a 11 caracteres           
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Nome Completo"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    inputProps={{ maxLength: 100 }} // Limita caracteres
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Data de Nascimento"
                    name="dataNascimento"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={formData.dataNascimento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    required
                    label="Estado Civil"
                    name="estadoCivil"
                    value={formData.estadoCivil}
                    onChange={handleChange}
                  >
                    {estadoCivilOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Profissão"
                    name="profissao"
                    value={formData.profissao}
                    onChange={handleChange}
                    inputProps={{ maxLength: 50 }} // Limita caracteres
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="RG"
                    name="rg"
                    value={formData.rg}
                    onChange={handleChange}
                    inputProps={{ maxLength: 15 }} // Limita caracteres
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Órgão Emissor"
                    name="orgaoEmissor"
                    value={formData.orgaoEmissor}
                    onChange={handleChange}
                    inputProps={{ maxLength: 10 }} // Limita caracteres
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Telefone"
                    name="telefone"
                    value={formData.telefone}
                    onChange={handleChange}
                    inputProps={{ maxLength: 15 }} // Limita caracteres
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Painel de Endereço */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <HomeIcon sx={{ mr: 1 }} />
              <Typography variant="h6">Endereço</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                  <TextField
                    fullWidth
                    required
                    label="CEP"
                    name="cep"
                    value={formData.cep}
                    onChange={handleChange}
                    error={!!cepError}
                    helperText={cepError}
                  />
                </Grid>
                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <IconButton onClick={handleCepSearch} color="primary" aria-label="Buscar CEP" sx={{ mt: 1 }}>
                    <SearchIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Endereço"
                    name="endereco"
                    value={formData.endereco}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Número"
                    name="numero"
                    value={formData.numero}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Complemento"
                    name="complemento"
                    value={formData.complemento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Bairro"
                    name="bairro"
                    value={formData.bairro}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Cidade"
                    name="cidade"
                    value={formData.cidade}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Estado"
                    name="estado"
                    value={formData.estado}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Painel de Dados de Login */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <LockIcon sx={{ mr: 1 }} />
              <Typography variant="h6">Dados de Acesso</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Senha"
                    name="senha"
                    value={formData.senha}
                    onChange={handleChange}
                    type="password"
                    inputProps={{ minLength: 6 }} // Senha deve ter pelo menos 6 caracteres
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Button
  type="submit"
  fullWidth
  variant="contained"
  sx={{
    mt: 3,
    mb: 2,
    backgroundColor: '#4CAF50',  // Define a cor de fundo personalizada
    '&:hover': {
      backgroundColor: '#45A049',  // Define a cor ao passar o mouse
    },
  }}
>
  Registrar-me
</Button>

          {/* Exibe mensagem de erro ou sucesso */}
          {status === "failed" && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          {/* Diálogo de sucesso */}
          <Dialog open={successDialogOpen} onClose={() => setSuccessDialogOpen(false)}>
            <DialogTitle>Cadastro Finalizado</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Seu cadastro foi realizado com sucesso! Deseja fazer login agora?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSuccessDialogOpen(false)} color="primary">
                Continuar
              </Button>
              <Button onClick={handleNavigateToLogin} color="primary" autoFocus>
                Fazer Login
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
};

export default CadastroBeneficiario;
