import React from "react";
import { Route, Routes } from "react-router-dom";
import Container from "@mui/material/Container";
import Topbar from "./layout/Topbar";
import Box from "@mui/material/Box";

// PÁGINAS DA ADMINISTRAÇÃO
import TodosMunicipios from "./pages/Administration/TodosMunicipios.js";
import Municipio from "./pages/Administration/Municipio.js";
import Nucleo from "./pages/Administration/Nucleo.js";
import MeuPerfil from "./pages/Common/MeuPerfil.js";
import AdminUsuarios from "./pages/Administration/AdminUsuarios.js";
import NovoUsuario from "./pages/Administration/NovoUsuario.js";

import ProtectedRoute from "./../components/pages/Administration/ProtectedRoute.js";  // Importa o componente de rota protegida

const DefaultLayout = () => {
  return (
    <>
      <Topbar />
      <Box>
        <Container maxWidth={false} disableGutters style={{ overflow: "auto" }}>
          <Routes>
            {/* PÁGINAS DE ADMINISTRAÇÃO */}
            <Route path="/municipios" element={<TodosMunicipios />} />
            <Route path="/municipios/:municipioId" element={<Municipio />} />
            <Route
              path="/municipios/:municipioId/nucleo/:nucleoId"
              element={<Nucleo />}
            />
            <Route path="/meuperfil" element={<MeuPerfil />} />

            {/* PÁGINAS PROTEGIDAS */}
            <Route
              path="/usuarios"
              element={
                <ProtectedRoute requiredRole="Administrador">
                  <AdminUsuarios />
                </ProtectedRoute>
              }
            />
            <Route
              path="/novousuario"
              element={
                <ProtectedRoute requiredRole="Administrador">
                  <NovoUsuario />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Container>
      </Box>
    </>
  );
};

export default DefaultLayout;
