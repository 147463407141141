import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Avatar,
  IconButton,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Grid,
  MenuItem,
  Tabs,
  Tab
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ref, get, child, remove, update } from 'firebase/database';
import { getAuth, deleteUser as deleteSelectedUser } from 'firebase/auth';
import { deleteObject, ref as storageRef } from 'firebase/storage';
import { auth, realtimeDB, storage } from '../../../firebase/config';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import PeopleIcon from '@mui/icons-material/People';
import { useNavigate } from 'react-router-dom';

// Importando componentes da Chart.js
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Registrando elementos da Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const AdminUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  // Função para buscar os usuários com permissões superiores a beneficiário
  const fetchUsuarios = async () => {
    setLoading(true);
    const dbRef = ref(realtimeDB, 'cpfs/');
    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      const cpfsData = snapshot.val();
      const userPromises = Object.entries(cpfsData).map(async ([cpf, user]) => {
        const userDetailsSnapshot = await get(child(ref(realtimeDB), `users/${user.uid}`));
        const userDetails = userDetailsSnapshot.val();
        return { ...userDetails, role: user.role };
      });
      const usersData = await Promise.all(userPromises);
      setUsuarios(usersData);
    }
    setLoading(false);
  };

  // Função para abrir o modal de visualização
  const handleViewClick = (user) => {
    setSelectedUser(user);
    setOpenViewModal(true);
  };

  // Função para abrir o modal de edição
  const handleEditClick = (user) => {
    setSelectedUser(user);
    setFormData(user);
    setOpenEditModal(true);
  };

  // Função para abrir o diálogo de confirmação ao clicar no botão de deletar
  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  // Função para confirmar a exclusão
  const handleConfirmDelete = async () => {
    if (!selectedUser) return;
    setDeleteInProgress(true);

    try {
      await remove(ref(realtimeDB, `cpfs/${selectedUser.cpf}`));
      await remove(ref(realtimeDB, `users/${selectedUser.uid}`));

      try {
        const userImageRef = storageRef(storage, `user/profileImages/${selectedUser.uid}`);
        await deleteObject(userImageRef);
      } catch (error) {
        console.log('Erro ao excluir a imagem ou imagem não existente:', error);
      }

      try {
        const authInstance = getAuth();
        const userToDelete = authInstance.currentUser;
        if (userToDelete && userToDelete.uid === selectedUser.uid) {
          await deleteSelectedUser(userToDelete);
        } else {
          console.warn('Usuário não encontrado no Firebase Authentication.');
        }
      } catch (authError) {
        console.error('Erro ao excluir usuário do Firebase Authentication:', authError);
      }

      setUsuarios((prevUsuarios) => prevUsuarios.filter((user) => user.uid !== selectedUser.uid));
    } catch (error) {
      console.error('Erro ao excluir usuário:', error);
    } finally {
      setDeleteInProgress(false);
      setOpenDialog(false);
    }
  };

  // Função para salvar as edições do usuário
  const handleSaveEdit = async () => {
    try {
      await update(ref(realtimeDB, `users/${formData.uid}`), formData);
      const cpfRef = ref(realtimeDB, `cpfs/${formData.cpf}`);
      await update(cpfRef, { uid: formData.uid, role: formData.role });

      setUsuarios((prevUsuarios) =>
        prevUsuarios.map((user) => (user.uid === formData.uid ? { ...user, ...formData } : user))
      );
      setOpenEditModal(false);
    } catch (error) {
      console.error('Erro ao salvar alterações:', error);
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddUser = () => {
    navigate('/novousuario');
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const filteredUsers = usuarios.filter((user) => {
    if (tabIndex === 0) return user.role === 'Beneficiário';
    if (tabIndex === 1) return user.role === 'Editor';
    if (tabIndex === 2) return user.role === 'Administrador';
    return false;
  });

  useEffect(() => {
    fetchUsuarios();
  }, []);

  // Função para contar os tipos de usuário
  const countRoles = () => {
    const roleCounts = { Beneficiário: 0, Editor: 0, Administrador: 0 };
    usuarios.forEach((user) => {
      if (user.role === 'Beneficiário') roleCounts.Beneficiário++;
      if (user.role === 'Editor') roleCounts.Editor++;
      if (user.role === 'Administrador') roleCounts.Administrador++;
    });
    return roleCounts;
  };

  const roleCounts = countRoles();

  const data = {
    labels: ['Beneficiários', 'Editores', 'Administradores'],
    datasets: [
      {
        data: [roleCounts.Beneficiário, roleCounts.Editor, roleCounts.Administrador],
        backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
      },
    ],
  };

  // Garantir que o gráfico seja destruído ao desmontar o componente
  useEffect(() => {
    return () => {
      const chart = ChartJS.getChart('chart-pizza');
      if (chart) {
        chart.destroy();
      }
    };
  }, []);

  const columns = [
    {
      field: 'photoURL',
      headerName: 'Foto',
      width: 100,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 1 }}>
          <Avatar src={params.value || '/default-avatar.png'} alt="User Avatar" sx={{ width: 40, height: 40 }} />
        </Box>
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'role',
      headerName: 'Permissão',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      width: 200,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 1 }}>
          <IconButton color="primary" aria-label="Ver Detalhes" onClick={() => handleViewClick(params.row)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton color="secondary" aria-label="Editar Usuário" onClick={() => handleEditClick(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton color="error" aria-label="Deletar Usuário" onClick={() => handleDeleteClick(params.row)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <Box sx={{ mt: 10, p: 3, display: 'flex', justifyContent: 'center' }}>
      <Paper
        elevation={4}
        sx={{
          width: '80%',
          maxWidth: 1000,
          p: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          <PeopleIcon sx={{ mr: 1 }} /> Administração de Usuários
        </Typography>

        {/* Gráfico de Pizza */}
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ width: '250px', height: '250px' }}> {/* Ajuste do tamanho do gráfico */}
              <Doughnut data={data} id="chart-pizza" />
            </Box>
          )}
        </Box>

        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="Beneficiários" />
          <Tab label="Editores" />
          <Tab label="Administradores" />
        </Tabs>

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 3 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddUser}
            sx={{ fontWeight: 'bold', textTransform: 'none' }}
          >
            Cadastrar Novo Usuário
          </Button>
        </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={filteredUsers}
              columns={columns}
              pageSize={5}
              localeText={{
                columnMenuSortAsc: 'Ordenar por ordem crescente',
                columnMenuSortDesc: 'Ordenar por ordem decrescente',
                columnMenuFilter: 'Filtrar',
                columnMenuHideColumn: 'Esconder coluna',
                columnMenuShowColumns: 'Mostrar colunas',
                filterPanelAddFilter: 'Adicionar filtro',
                filterPanelDeleteIconLabel: 'Remover',
                filterPanelOperators: 'Operadores',
                filterPanelColumns: 'Colunas',
                filterPanelInputLabel: 'Valor',
                filterPanelOperatorAnd: 'E',
                filterPanelOperatorOr: 'Ou',
                columnsPanelTextFieldLabel: 'Procurar coluna',
                columnsPanelHideAllButton: 'Esconder todas',
                columnsPanelShowAllButton: 'Mostrar todas',
                footerRowSelected: (count) => `${count.toLocaleString()} linha(s) selecionada(s)`,
                footerTotalRows: 'Total de linhas:',
                footerPaginationRowsPerPage: 'Linhas por página:',
              }}
              disableSelectionOnClick
              rowHeight={55}
              getRowId={(row) => row.uid}
              pagination
            />
          </Box>
        )}

        {/* Modais e Diálogos */}
        {/* Modal de visualização de usuário */}
        <Dialog open={openViewModal} onClose={() => setOpenViewModal(false)} fullWidth maxWidth="md">
          <DialogTitle>Detalhes do Usuário</DialogTitle>
          <DialogContent>
            <DialogContentText>Informações detalhadas do usuário {selectedUser?.nome}.</DialogContentText>
            {selectedUser && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Nome" value={selectedUser.nome || ''} InputProps={{ readOnly: true }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Email" value={selectedUser.email || ''} InputProps={{ readOnly: true }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Telefone"
                    value={selectedUser.telefone || ''}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="CPF" value={selectedUser.cpf || ''} InputProps={{ readOnly: true }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Permissão" value={selectedUser.role || ''} InputProps={{ readOnly: true }} />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenViewModal(false)} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Modal de edição de usuário */}
        <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)} fullWidth maxWidth="md">
          <DialogTitle>Editar Usuário</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Nome" name="nome" value={formData.nome || ''} onChange={handleEditChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Email" name="email" value={formData.email || ''} onChange={handleEditChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Telefone"
                  name="telefone"
                  value={formData.telefone || ''}
                  onChange={handleEditChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="CPF" name="cpf" value={formData.cpf || ''} InputProps={{ readOnly: true }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Permissão"
                  name="role"
                  value={formData.role || ''}
                  onChange={handleEditChange}
                >
                  <MenuItem value="Administrador">Administrador</MenuItem>
                  <MenuItem value="Editor">Editor</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEditModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleSaveEdit} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Diálogo de confirmação de exclusão */}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Confirmar Exclusão</DialogTitle>
          <DialogContent>
            <DialogContentText>Tem certeza que deseja excluir o usuário {selectedUser?.nome}?</DialogContentText>
            {deleteInProgress && <LinearProgress sx={{ mt: 2 }} />}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleConfirmDelete} color="error" disabled={deleteInProgress}>
              Apagar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Box>
  );
};

export default AdminUsuarios;
