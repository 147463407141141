import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { auth, realtimeDB } from "../../../firebase/config";
import { get, ref } from "firebase/database";


const ProtectedRoute = ({ children, requiredRole }) => {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const usersRef = ref(realtimeDB, `users/${currentUser.uid}`);
        const snapshot = await get(usersRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          setUserRole(userData.role);
        }
      }
      setLoading(false);
    };

    fetchUserRole();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (userRole !== requiredRole) {
    return <Navigate to="/entrar" />;
  }

  return children;
};

export default ProtectedRoute;