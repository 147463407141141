import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase/config.js"; // Importe sua configuração do Firebase
import { Button, TextField, Box, Typography, Container, Alert } from "@mui/material";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!email) {
      setError("Por favor, insira um endereço de email válido.");
      return;
    }

    setIsLoading(true);

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccessMessage("Um link de redefinição de senha foi enviado para seu e-mail.");
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        const errorCode = error.code;
        switch (errorCode) {
          case "auth/user-not-found":
            setError("Nenhum usuário encontrado com este endereço de e-mail.");
            break;
          case "auth/invalid-email":
            setError("O endereço de e-mail informado é inválido.");
            break;
          default:
            setError("Ocorreu um erro. Por favor, tente novamente mais tarde.");
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Redefinir Senha
        </Typography>
        <Box component="form" onSubmit={handleResetPassword} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Endereço de Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {successMessage}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading ? "Enviando..." : "Enviar link de redefinição"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPassword;
