import styled from 'styled-components';
import "@cyntler/react-doc-viewer/dist/index.css";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Chip,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useParams, useNavigate } from "react-router-dom";  // Import useNavigate
import { ref, get, push, remove } from "firebase/database";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { realtimeDB, storage, auth } from "../../../firebase/config";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const HideDownloadButton = styled.div`
  #pdf-download {
    display: none;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Nucleo = () => {
  const { municipioId, nucleoId } = useParams();
  const [nucleo, setNucleo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [fileType, setFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [filesData, setFilesData] = useState([]);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const navigate = useNavigate();  // Initialize useNavigate

  const NomeCidade = {
    catuji: "Catuji - MG",
    carai: "Caraí - MG",
    itaipe: "Itaipé - MG",
    padreparaiso: "P. Paraíso - MG",
    novooriente: "Novo Oriente - MG",
    ouroverde: "Ouro Verde - MG",
  }[municipioId] || "Cidade Desconhecida";

  useEffect(() => {
    const fetchNucleoData = async () => {
      const nucleoRef = ref(
        realtimeDB,
        `municipios/${municipioId}/nucleos/${nucleoId}`
      );
      const snapshot = await get(nucleoRef);

      if (snapshot.exists()) {
        const nucleoData = snapshot.val();
        setNucleo(nucleoData);
        const filesArray = nucleoData.pecas_tecnicas
          ? Object.entries(nucleoData.pecas_tecnicas).map(([id, data]) => ({
              id,
              ...data,
            }))
          : [];
        setFilesData(filesArray);
      } else {
        console.error("Núcleo não encontrado!");
      }
      setLoading(false);
    };
    fetchNucleoData();
  }, [municipioId, nucleoId]);

  // Fetch users when the "Beneficiários" tab is selected
  useEffect(() => {
    if (value === 1) { // When Beneficiários tab is selected
      const fetchUsers = async () => {
        setLoadingUsers(true);
        const usersRef = ref(realtimeDB, "users/");
        const snapshot = await get(usersRef);
        if (snapshot.exists()) {
          const allUsers = snapshot.val();
          const filteredUsers = Object.values(allUsers).filter(
            (user) => user.municipioId === municipioId && user.nucleoId === nucleoId
          );
          setUsersData(filteredUsers);
        }
        setLoadingUsers(false);
      };
      fetchUsers();
    }
  }, [value, municipioId, nucleoId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedFile(null);
    setFileType("");
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile || !fileType) {
      alert("Por favor, selecione um tipo de arquivo e um arquivo.");
      return;
    }

    setUploading(true);
    const currentUserEmail = auth.currentUser.email;
    const uploadDate = new Date().toLocaleString();
    const fileName = selectedFile.name.split(".").slice(0, -1).join(".");
    const fileExtension = selectedFile.name.split(".").pop().toUpperCase();
    const fileSize = (selectedFile.size / (1024 * 1024)).toFixed(2);
    const folderPath = `municipios/${municipioId}/nucleos/${nucleoId}/pecas_tecnicas/${fileType}/`;

    try {
      const fileRef = storageRef(storage, folderPath + selectedFile.name);
      await uploadBytes(fileRef, selectedFile);
      const fileUrl = await getDownloadURL(fileRef);

      const fileData = {
        nome: fileName,
        url: fileUrl,
        tamanho: `${fileSize} MB`,
        tipo: fileType,
        extensao: fileExtension,
        anexadoPor: currentUserEmail,
        dataPublicacao: uploadDate,
      };

      const dbRef = ref(
        realtimeDB,
        `municipios/${municipioId}/nucleos/${nucleoId}/pecas_tecnicas`
      );
      const newFileRef = await push(dbRef, fileData);
      setFilesData((prev) => [...prev, { id: newFileRef.key, ...fileData }]);

      setSelectedFile(null);
      setFileType("");
      handleCloseModal();
    } catch (error) {
      console.error("Erro ao fazer upload do arquivo:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteFile = async (fileId, fileUrl) => {
    try {
      await remove(
        ref(
          realtimeDB,
          `municipios/${municipioId}/nucleos/${nucleoId}/pecas_tecnicas/${fileId}`
        )
      );
      const fileRef = storageRef(storage, fileUrl);
      await deleteObject(fileRef);
      setFilesData((prev) => prev.filter((file) => file.id !== fileId));
    } catch (error) {
      console.error("Erro ao deletar arquivo:", error);
    }
  };

  const getChipColor = (extension) => {
    switch (extension) {
      case "PDF":
        return "error";
      case "JPG":
      case "PNG":
        return "primary";
      case "DXF":
        return "secondary";
      case "DOCX":
        return "success";
      default:
        return "default";
    }
  };

  const handleOpenViewer = (url) => {
    setFileUrl(url);
    setOpenFileModal(true);
  };

  const handleCloseViewer = () => {
    setOpenFileModal(false);
    setFileUrl(null);
  };

  const fileTypes = [
    { value: "Areas_Administrativas", label: "Áreas Administrativas" },
    { value: "Mapas", label: "Mapas" },
    { value: "Memoriais_Quadras", label: "Memoriais de Quadras" },
    { value: "Memoriais_Ruas", label: "Memoriais de Ruas" },
    { value: "Memorial_Perimetro", label: "Memorial de Perímetro" },
    { value: "Plantas_Lotes", label: "Plantas Individuais de Lotes" },
    { value: "Relatorios_Areas", label: "Relatórios de Áreas" },
  ];

  const renderFilesByType = (type) => {
    const filteredFiles = filesData.filter((file) => file.tipo === type);

    const columns = [
      { field: "nome", headerName: "Nome do Arquivo", flex: 1 },
      { field: "dataPublicacao", headerName: "Data de Publicação", flex: 1 },
      { field: "tamanho", headerName: "Tamanho", flex: 1 },
      {
        field: "extensao",
        headerName: "Extensão",
        flex: 1,
        renderCell: (params) => (
          <Chip label={params.value} color={getChipColor(params.value)} size="small" />
        ),
      },
      {
        field: "acoes",
        headerName: "Ações",
        flex: 1,
        renderCell: (params) => (
          <Box>
            <IconButton onClick={() => handleOpenViewer(params.row.url)} color="primary">
              <VisibilityIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteFile(params.id, params.row.url)} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
    ];

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {fileTypes.find((ft) => ft.value === type)?.label || "Tipo Desconhecido"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid rows={filteredFiles} columns={columns} pageSize={5} getRowId={(row) => row.id} />
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  const userColumns = [
    { field: "nome", headerName: "Nome", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "cidade", headerName: "Cidade", flex: 1 },
    { field: "nucleo", headerName: "Núcleo", flex: 1 },
    { field: "role", headerName: "Permissão", flex: 1 },
  ];

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Container style={{ overflow: "auto", marginTop: "100px" }}>
        <div>
          <Card sx={{ display: "flex" }}>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={"/img/assets/" + municipioId + ".png" || "/img/default-image.png"}
              alt={nucleo?.nome || "Núcleo"}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  {nucleo?.nome || "Núcleo Desconhecido"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  {NomeCidade}
                </Typography>
              </CardContent>
            </Box>
          </Card>

          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="icon label tabs example"
              variant="fullWidth"
            >
              <Tab icon={<FilePresentIcon />} label="Peças Técnicas" />
              <Tab icon={<PersonPinIcon style={{ color: "blue" }} />} label="Beneficiários" />
            </Tabs>

            <TabPanel value={value} index={0}>
              <Box sx={{ display: "flex", justifyContent: "start", mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<FilePresentIcon />}
                  onClick={handleOpenModal}
                >
                  Anexar Peças Técnicas
                </Button>
              </Box>

              {fileTypes.map((fileType) => renderFilesByType(fileType.value))}
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
                {/* Botão para cadastrar novo beneficiário */}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PersonPinIcon />}
                  onClick={() => navigate("/novousuario")}  // Navega para a página de cadastro de usuário
                >
                  Cadastrar Beneficiário
                </Button>
              </Box>

              {loadingUsers ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Box sx={{ height: 400, width: "100%" }}>
                  <DataGrid rows={usersData} columns={userColumns} pageSize={5} getRowId={(row) => row.cpf} />
                </Box>
              )}
            </TabPanel>
          </Box>
        </div>

        {/* Modal para anexar peças técnicas */}
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Anexar Peças Técnicas</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Tipo de Peça Técnica</InputLabel>
              <Select
                value={fileType}
                onChange={(e) => setFileType(e.target.value)}
                fullWidth
              >
                {fileTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              type="file"
              onChange={handleFileChange}
              fullWidth
              sx={{ mt: 3 }}
              inputProps={{ accept: ".pdf,.jpg,.png,.dxf" }}
            />
            {uploading && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <CircularProgress />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleUpload} color="primary" disabled={uploading}>
              {uploading ? "Enviando..." : "Enviar"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Modal para visualizar Arquivos (PDF, DOCX, PNG, JPG) */}
        <Dialog open={openFileModal} onClose={handleCloseViewer} fullScreen>
          <DialogTitle>Visualizar Arquivo</DialogTitle>
          <DialogContent>
            {fileUrl && (
              <Box sx={{ height: "80vh" }}>
                <HideDownloadButton>
                  <DocViewer
                    documents={[{ uri: fileUrl }]}
                    pluginRenderers={DocViewerRenderers}
                    config={{
                      header: {
                        disableHeader: false,
                        disableFileName: true,
                        retainURLParams: false,
                      },
                      csvDelimiter: ",",
                      pdfZoom: {
                        defaultZoom: 1.1,
                        zoomJump: 0.2,
                      },
                      pdfVerticalScrollByDefault: true,
                    }}
                  />
                </HideDownloadButton>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseViewer} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default Nucleo;
