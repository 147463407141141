import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  CircularProgress,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux';
import { cadastrarBeneficiario, selectUserDetails } from '../../../store/usersSlices';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth, realtimeDB } from '../../../firebase/config';
import { ref, get } from 'firebase/database';
import { useSelector } from 'react-redux';

const municipiosArray = [
  { nome: 'Catuji - MG', municipioId: 'catuji' },
  { nome: 'Caraí - MG', municipioId: 'carai' },
  { nome: 'Itaipé - MG', municipioId: 'itaipe' },
  { nome: 'P. Paraíso - MG', municipioId: 'padreparaiso' },
  { nome: 'Novo Oriente - MG', municipioId: 'novooriente' },
  { nome: 'Ouro Verde - MG', municipioId: 'ouroverde' },
];

const estadoCivilOptions = [
  { value: 'Solteiro(a)', label: 'Solteiro(a)' },
  { value: 'Casado(a)', label: 'Casado(a)' },
  { value: 'Divorciado(a)', label: 'Divorciado(a)' },
  { value: 'Viúvo(a)', label: 'Viúvo(a)' },
];

const roles = [
  { value: 'Beneficiário', label: 'Beneficiário' },
  { value: 'Editor', label: 'Editor' },
  { value: 'Administrador', label: 'Administrador' },
];

const NovoUsuario = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nucleos, setNucleos] = useState([]);
  const [selectedMunicipio, setSelectedMunicipio] = useState('');
  const [selectedNucleo, setSelectedNucleo] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });
  const userDt = useSelector(selectUserDetails);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    cpf: '',
    rg: '',
    profissao: '',
    senha: '123456',
    dataNascimento: '',
    estadoCivil: '',
    nomePai: '',
    nomeMae: '',
    nomeConjuge: '',
    cpfConjuge: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    cep: '',
    role: 'Beneficiário',  // Default role
    cadastradoPor: userDt.email || '',
    latitude: null,
    longitude: null,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [cepError, setCepError] = useState('');

  // Function to retrieve precise location with high accuracy enabled
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setFormData((prevData) => ({
            ...prevData,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
        },
        (error) => {
          console.error('Error getting location', error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    }
  }, []);

  // Fetch nucleos from the database based on the selected municipality
  useEffect(() => {
    if (selectedMunicipio) {
      const fetchNucleos = async () => {
        const nucleosRef = ref(realtimeDB, `municipios/${selectedMunicipio}/nucleos`);
        const snapshot = await get(nucleosRef);
        if (snapshot.exists()) {
          const nucleosData = snapshot.val();
          setNucleos(Object.entries(nucleosData).map(([id, data]) => ({ id, ...data })));
        } else {
          setNucleos([]); // Clear dropdown if no nucleos are found
        }
      };
      fetchNucleos();
    } else {
      setNucleos([]);
    }
  }, [selectedMunicipio]);

  const handleCpfChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setFormData({ ...formData, cpf: value });
  };

  const handleCepSearch = async () => {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${formData.cep}/json/`);
      if (response.data.erro) {
        setCepError('CEP não encontrado!');
        return;
      }
      setFormData({
        ...formData,
        endereco: response.data.logradouro,
        bairro: response.data.bairro,
        cidade: response.data.localidade,
        estado: response.data.uf,
      });
      setCepError('');
    } catch (error) {
      setCepError('Erro ao buscar o CEP.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEstadoCivilChange = (e) => {
    setEstadoCivil(e.target.value);
    setFormData({ ...formData, estadoCivil: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    // Define municipality and núcleo only if the new user is a Beneficiary
    const municipioToSubmit = formData.role === 'Beneficiário' ? selectedMunicipio : 'defaultMunicipio';
    const nucleoToSubmit = formData.role === 'Beneficiário' ? selectedNucleo : 'defaultNucleo';

    try {
      await dispatch(
        cadastrarBeneficiario({
          ...formData,
          municipioId: municipioToSubmit,
          nucleoId: nucleoToSubmit,
        })
      ).unwrap();
      navigate('/usuarios');
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5, p: 3 }}>
      <Paper elevation={4} sx={{ p: 3, maxWidth: 800, width: '100%' }}>
        <Typography variant="h4" align="center" gutterBottom>
          Cadastro de Usuário
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          {/* Painel de Dados Pessoais */}
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Dados Pessoais</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField label="Nome" name="nome" fullWidth required value={formData.nome} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Email" name="email" fullWidth required value={formData.email} onChange={handleChange} type="email" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Telefone" name="telefone" fullWidth required value={formData.telefone} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="CPF" name="cpf" fullWidth required value={formData.cpf} onChange={handleCpfChange} inputProps={{ maxLength: 11 }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="RG" name="rg" fullWidth value={formData.rg} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Profissão" name="profissao" fullWidth value={formData.profissao} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Nome do Pai" name="nomePai" fullWidth value={formData.nomePai} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Nome da Mãe" name="nomeMae" fullWidth value={formData.nomeMae} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label="Estado Civil"
                    name="estadoCivil"
                    fullWidth
                    required
                    value={estadoCivil}
                    onChange={handleEstadoCivilChange}
                  >
                    {estadoCivilOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {estadoCivil === 'Casado(a)' && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField label="Nome do Cônjuge" name="nomeConjuge" fullWidth value={formData.nomeConjuge} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField label="CPF do Cônjuge" name="cpfConjuge" fullWidth value={formData.cpfConjuge} onChange={handleCpfChange} inputProps={{ maxLength: 11 }} />
                    </Grid>
                  </>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Painel de Endereço */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Endereço</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                  <TextField label="CEP" name="cep" fullWidth value={formData.cep} onChange={handleChange} error={!!cepError} helperText={cepError} />
                </Grid>
                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <IconButton onClick={handleCepSearch} color="primary" aria-label="Buscar CEP">
                    <SearchIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Endereço" name="endereco" fullWidth value={formData.endereco} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField label="Número" name="numero" fullWidth value={formData.numero} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField label="Complemento" name="complemento" fullWidth value={formData.complemento} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Bairro" name="bairro" fullWidth value={formData.bairro} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Cidade" name="cidade" fullWidth value={formData.cidade} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Estado" name="estado" fullWidth value={formData.estado} onChange={handleChange} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Painel de Município e Núcleo */}
          {formData.role === 'Beneficiário' && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Município e Núcleo</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      label="Município"
                      name="municipio"
                      fullWidth
                      required
                      value={selectedMunicipio}
                      onChange={(e) => setSelectedMunicipio(e.target.value)}
                    >
                      {municipiosArray.map((municipio) => (
                        <MenuItem key={municipio.municipioId} value={municipio.municipioId}>
                          {municipio.nome}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      label="Núcleo"
                      name="nucleo"
                      fullWidth
                      required
                      value={selectedNucleo}
                      onChange={(e) => setSelectedNucleo(e.target.value)}
                      disabled={!selectedMunicipio}
                    >
                      {nucleos.map((nucleo) => (
                        <MenuItem key={nucleo.id} value={nucleo.id}>
                          {nucleo.nome}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}

          {/* Painel de Permissão */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Permissão do Usuário</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                select
                label="Permissão"
                name="role"
                fullWidth
                value={formData.role}
                onChange={handleChange}
              >
                {roles.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </AccordionDetails>
          </Accordion>

          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <CircularProgress />
            </Box>
          )}

          {error && (
            <Typography color="error" align="center" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button type="submit" variant="contained" color="primary">
              Cadastrar
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default NovoUsuario;
