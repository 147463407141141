import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { auth, realtimeDB, storage } from "./../firebase/config.js";
import { ref, child, push, set, get, update, remove } from "firebase/database";
import { uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { ref as refStorage } from "firebase/storage";
import { kml } from "@tmcw/togeojson";
import JSZip from "jszip";
import * as shapefile from "shapefile";
import generateGUID from "../components/pages/Administration/XFunctions.js";

// Thunk para buscar núcleos de um município
export const fetchMaps = createAsyncThunk("maps/fetchData", async (municipioId) => {
  const dbRef = ref(realtimeDB, `municipios/${municipioId}/nucleos`);
  const snapshot = await get(dbRef);
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    throw new Error("Nenhum núcleo encontrado para este município.");
  }
});

// Thunk para adicionar um novo núcleo
export const addMap = createAsyncThunk("maps/addMap", async ({ municipioId, nucleo }) => {
  const dbRef = ref(realtimeDB, `municipios/${municipioId}/nucleos`);
  const newNucleoRef = push(dbRef); // Cria um novo ID para o núcleo
  await set(newNucleoRef, { nome: nucleo });
  return { id: newNucleoRef.key, nome: nucleo };
});

// Thunk para editar um núcleo
export const editMap = createAsyncThunk("maps/editMap", async ({ municipioId, nucleoId, novoNome }) => {
  const nucleoRef = ref(realtimeDB, `municipios/${municipioId}/nucleos/${nucleoId}`);
  await update(nucleoRef, { nome: novoNome }); // Atualiza o nome do núcleo
  return { nucleoId, novoNome };
});

// Thunk para apagar um núcleo
export const eraseMap = createAsyncThunk("maps/eraseMap", async ({ municipioId, nucleoId }) => {
  const nucleoRef = ref(realtimeDB, `municipios/${municipioId}/nucleos/${nucleoId}`);
  await remove(nucleoRef); // Remove o núcleo do banco de dados

  // Apagar arquivos relacionados ao núcleo (se necessário)
  const storagePath = `municipios/${municipioId}/nucleos/${nucleoId}`;
  const storageRef = refStorage(storage, storagePath);
  try {
    await deleteObject(storageRef); // Remove arquivos do Firebase Storage se existirem
  } catch (error) {
    console.log("Erro ao remover arquivos do núcleo, ou não há arquivos para remover:", error);
  }
  return nucleoId;
});

// Slice do Redux para gerenciar o estado de mapas (núcleos)
export const mapsSlice = createSlice({
  name: "maps",
  initialState: {
    maps: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
      state.maps = [];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMaps.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMaps.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.maps = Object.entries(action.payload).map(([id, map]) => ({ id, ...map }));
      })
      .addCase(fetchMaps.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addMap.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.maps.push(action.payload);
      })
      .addCase(addMap.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(editMap.fulfilled, (state, action) => {
        const { nucleoId, novoNome } = action.payload;
        const mapIndex = state.maps.findIndex((map) => map.id === nucleoId);
        if (mapIndex !== -1) {
          state.maps[mapIndex].nome = novoNome; // Atualiza o nome do núcleo no estado
        }
      })
      .addCase(editMap.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(eraseMap.fulfilled, (state, action) => {
        state.maps = state.maps.filter((map) => map.id !== action.payload); // Remove o núcleo do estado
      })
      .addCase(eraseMap.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Selectores para acessar o estado dos mapas
export const selectMaps = (state) => state.maps.maps;
export const selectMapsStatus = (state) => state.maps.status;
export const selectMapsError = (state) => state.maps.error;
export const { setStatus } = mapsSlice.actions;
export default mapsSlice.reducer;
