import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUserDetails, setUser } from "../../store/usersSlices";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/config.js";
import LocationCityIcon from "@mui/icons-material/LocationCity"; // Ícone para Municípios
import PeopleIcon from "@mui/icons-material/People"; // Ícone para Usuários

// Links de navegação com ícones
const links = [
  { name: "Municípios", path: "/municipios", icon: <LocationCityIcon /> },
  { name: "Usuários", path: "/usuarios", icon: <PeopleIcon /> },
];

const settings = ["Meu Perfil", "Sair"];

const Topbar = () => {
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Estados de ancoragem para menus
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  // Abrir e fechar menus
  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  // Função para Sign Out
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        dispatch(setUser(null));
        navigate("/");
      })
      .catch((error) => console.log(error));
  };

  // Mapeia os links com ícones para os botões de navegação
  const renderNavLinks = () =>
    links
      .filter((link) => link.name !== "Usuários" || userDetails?.role === "Administrador") // Exibe "Usuários" apenas para Administradores
      .map((link) => (
        <Link
          key={link.name}
          to={link.path}
          style={{ textDecoration: "none", color: "white" }}
        >
          <Button
            onClick={handleCloseNavMenu}
            sx={{ my: 1, color: "white", display: "flex", alignItems: "center" }}
          >
            {link.icon} &nbsp; {link.name}
          </Button>
        </Link>
      ));

  // Mapeia as opções de usuário (Minha Conta, Sair)
  const renderUserSettings = () =>
    settings.map((setting) => (
      <MenuItem
        key={setting}
        onClick={() => {
          if (setting === "Sair") {
            handleSignOut();
          } else if (setting === "Meu Perfil") {
            navigate("/meuperfil"); // Redireciona para a página do perfil
          }
          handleCloseUserMenu();
        }}
      >
        <Typography textAlign="center">{setting}</Typography>
      </MenuItem>
    ));

  return (
    <AppBar position="fixed" color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logotipo - substitui o título REURBCIDADE */}
          <Box
            component="a"
            sx={{
              display: { xs: "none", md: "flex" },
              cursor: "pointer",
              mr: 4,
            }}
            onClick={() => navigate("/municipios")} // Navega para Municípios ao clicar no logotipo
          >
            <img
              src="/img/logo_white.png" // Caminho da imagem do logo
              alt="ReurbCidade Logo"
              style={{ height: "50px" }} // Ajusta a altura do logo para se adaptar bem ao AppBar
            />
          </Box>

          {/* Menu para dispositivos móveis */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="open navigation"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {links
                .filter(
                  (link) => link.name !== "Usuários" || userDetails?.role === "Administrador"
                )
                .map((link) => (
                  <Link
                    key={link.name}
                    to={link.path}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">
                        {link.icon} &nbsp; {link.name}
                      </Typography>
                    </MenuItem>
                  </Link>
                ))}
            </Menu>
          </Box>

          {/* Logotipo para versão mobile - Centralizado */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "left", // Centraliza o logotipo horizontalmente
              alignItems: "center", // Centraliza verticalmente
              cursor: "pointer",
            }}
            onClick={() => navigate("/municipios")} // Navega para Municípios ao clicar no logotipo
          >
            <img
              src="/img/logo_white.png"
              alt="ReurbCidade Logo"
              style={{ height: "40px" }} // Tamanho menor para dispositivos móveis
            />
            Gestão de Área
          </Box>

          {/* Menu de navegação desktop */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {renderNavLinks()}
          </Box>

          {/* Avatar e menu de usuário */}
          <Box sx={{ flexGrow: 0 }}>
            {userDetails?.photoURL ? (
              <Tooltip title="Minha Conta">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="User Avatar"
                    src={userDetails.photoURL || "/default-avatar.png"}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Entrar">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="User Avatar" />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {renderUserSettings()}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Topbar;
