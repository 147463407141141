import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doc, getDoc } from "firebase/firestore"
import { db } from './../firebase/config.js'

export const currentMapSlices = createSlice({
    name: 'maps',
    initialState: {
        mapId: null,
        initStatus: 'idle'
    },
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchInitMap.pending, (state, action) => {
                // STATUS DE CARREGAMENTO INICIAL DO MAPA
                state.initStatus = 'loading';       
            })
            .addCase(fetchInitMap.fulfilled, (state, action) => {
                // O CARREGAMENTO DO MAPA FOI BEM SUCEDIDO
                state.initStatus = 'succeeded';
               
            })
            .addCase(fetchInitMap.rejected, (state, action) => {
                state.initStatus = 'failed';
                console.log(action.error.message);
            })
    }
})
export const selectCurrentMap = state => state.currentMap
export default currentMapSlices.reducer;
// BUSCA OS MAPAS DO USUÁRIO LOGADO
export const fetchInitMap = createAsyncThunk('currentMapSlices/fetchInitMap', async (payload, mapElement) => {
    const docRef = doc(db, "maps", payload);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return {id: payload, ...docSnap.data() };
    } else {
        console.log("C3DFARM ERROR: No such document map.");
        return null;
    }
})






// APAGA UM MAPA
/* export const addMap = createAsyncThunk('maps/addMap', async (payload) => {
  let newMap = {
    ...payload,
    userId: auth.currentUser.uid
  }
  const docRef = await addDoc(collection(db, "maps"), newMap)
  newMap.id = docRef.id
  return newMap
})

export const eraseMap = createAsyncThunk('maps/eraseMap', async (payload) => {
  await deleteDoc(doc(db, "maps", payload))
  return payload
}) */
