import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, FormControl, InputLabel, CircularProgress, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { setStatus } from "../../../store/mapsSlices";
import { useDispatch } from "react-redux";
import { ref, get, update, remove } from "firebase/database";
import { realtimeDB, auth } from "../../../firebase/config"; // Firebase config

const municipios = [
  { nome: "Catuji - MG", imagem: "/img/assets/catuji.png", link: "/municipios/catuji", id: "catuji" },
  { nome: "Caraí - MG", imagem: "/img/assets/carai.png", link: "/municipios/carai", id: "carai" },
  { nome: "Itaipé - MG", imagem: "/img/assets/itaipe.png", link: "/municipios/itaipe", id: "itaipe" },
  { nome: "P. Paraíso - MG", imagem: "/img/assets/padreparaiso.png", link: "/municipios/padreparaiso", id: "padreparaiso" },
  { nome: "Novo Oriente - MG", imagem: "/img/assets/novooriente.png", link: "/municipios/novooriente", id: "novooriente" },
  { nome: "Ouro Verde - MG", imagem: "/img/assets/ouroverde.png", link: "/municipios/ouroverde", id: "ouroverde" },
];

const TodosMunicipios = () => {
  const dispatch = useDispatch();
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [allowedMunicipios, setAllowedMunicipios] = useState([]); // Municípios permitidos para Editores
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(""); // Guardar o UID do usuário selecionado
  const [availableUsers, setAvailableUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [selectedMunicipio, setSelectedMunicipio] = useState(null);
  const [currentPermissions, setCurrentPermissions] = useState({}); // Permissões ativas para o município

  useEffect(() => {
    dispatch(setStatus("idle"));
    fetchCurrentUserRole();
  }, []);

  // Busca as permissões do usuário atual e define a role
  const fetchCurrentUserRole = async () => {
    const currentUserEmail = auth.currentUser.email;
    const usersRef = ref(realtimeDB, "users/");
    const snapshot = await get(usersRef);

    if (snapshot.exists()) {
      const allUsers = snapshot.val();
      const currentUser = Object.values(allUsers).find(user => user.email === currentUserEmail);
      setCurrentUserRole(currentUser?.role || null);

      if (currentUser?.role === "Editor") {
        // Filtra os municípios permitidos para os Editores
        const allowed = await fetchAllowedMunicipiosForEditor(currentUser.uid);
        setAllowedMunicipios(allowed);
      }
    }
  };

  // Busca os municípios permitidos para o editor
  const fetchAllowedMunicipiosForEditor = async (userUid) => {
    const permissionsRef = ref(realtimeDB, "municipios");
    const snapshot = await get(permissionsRef);

    if (snapshot.exists()) {
      const municipiosData = snapshot.val();
      return Object.keys(municipiosData).filter((municipioId) => {
        const municipio = municipiosData[municipioId];
        return municipio.permissions && municipio.permissions[userUid];
      });
    }

    return [];
  };

  // Função para buscar as permissões ativas para um município
  const fetchPermissions = async (municipioId) => {
    const permissionsRef = ref(realtimeDB, `municipios/${municipioId}/permissions`);
    const snapshot = await get(permissionsRef);
    return snapshot.exists() ? snapshot.val() : {};
  };

  // Modal de permissões de usuários
  const handleOpenPermissionsModal = async (municipio) => {
    setSelectedMunicipio(municipio);
    fetchUsers();

    // Buscar permissões ativas
    const permissions = await fetchPermissions(municipio.id);
    setCurrentPermissions(permissions);

    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedUser("");
  };

  const fetchUsers = async () => {
    setLoadingUsers(true);
    const usersRef = ref(realtimeDB, "users/");
    const snapshot = await get(usersRef);

    if (snapshot.exists()) {
      const allUsers = snapshot.val();
      const filteredUsers = Object.values(allUsers).filter(user => user.role === "Editor" || user.role === "Administrador");
      setAvailableUsers(filteredUsers);
    }
    setLoadingUsers(false);
  };

  // Função para adicionar permissão (UID + email)
  const handleAddUserPermission = async () => {
    if (selectedUser && selectedMunicipio) {
      const selectedUserData = availableUsers.find(user => user.uid === selectedUser); // Pega os dados do usuário selecionado
      const municipioRef = ref(realtimeDB, `municipios/${selectedMunicipio.id}/permissions`);
      const updatedPermissions = {
        ...currentPermissions,
        [selectedUser]: { email: selectedUserData.email }, // Grava UID e email
      };

      try {
        // Atualiza as permissões no Firebase
        await update(municipioRef, updatedPermissions);
        setOpenModal(false);
        setSelectedUser("");
      } catch (error) {
        console.error("Erro ao adicionar permissão:", error);
      }
    }
  };

  // Função para remover permissão
  const handleRemoveUserPermission = async (uid) => {
    const municipioRef = ref(realtimeDB, `municipios/${selectedMunicipio.id}/permissions/${uid}`);
    try {
      await remove(municipioRef);
      const updatedPermissions = { ...currentPermissions };
      delete updatedPermissions[uid]; // Remove a permissão localmente
      setCurrentPermissions(updatedPermissions);
    } catch (error) {
      console.error("Erro ao remover permissão:", error);
    }
  };

  return (
    <Box sx={{ mt: 10, p: 3, display: 'flex', justifyContent: 'center' }}>
      <Paper elevation={4} sx={{ width: '80%', maxWidth: 1000, p: 3, textAlign: 'center' }}>
        <Typography sx={{ mb: 4 }} variant="h4" align="center" gutterBottom>
          <LocationCityIcon sx={{ mr: 1 }} /> Municípios
        </Typography>

        <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" alignItems="center" paddingBottom={10}>
          {municipios.map((municipio, index) => {
            const isAdmin = currentUserRole === "Administrador";
            const hasAccess = isAdmin || allowedMunicipios.includes(municipio.id); // Verifica se o editor tem permissão

            return (
              <Grid item xs={12} sm={6} md={4} key={municipio.nome}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardMedia component="img" sx={{ height: 200 }} image={municipio.imagem} title={municipio.nome} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {municipio.nome}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "center" }}>
                    {/* Verifica as permissões do usuário antes de habilitar o botão */}
                    {hasAccess ? (
                      <Link to={municipio.link} style={{ textDecoration: "none" }}>
                        <Button
                          size="large"
                          variant="outlined"
                          color="warning"
                          sx={{ display: "flex", justifyContent: "center", width: 150 }}
                        >
                          <FolderOpenIcon fontSize="large" />
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        size="large"
                        variant="outlined"
                        color="warning"
                        sx={{ display: "flex", justifyContent: "center", width: 150 }}
                        disabled
                      >
                        <FolderOpenIcon fontSize="large" />
                      </Button>
                    )}
                    {/* Somente Administradores podem ver o botão de permissões */}
                    {isAdmin && (
                      <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        sx={{ display: "flex", justifyContent: "center", width: 150 }}
                        onClick={() => handleOpenPermissionsModal(municipio)}
                      >
                        <SettingsIcon fontSize="large" />
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Paper>

      {/* Modal para adicionar permissões de usuários */}
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Adicionar Permissões de Acesso</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Usuários com permissão ativa:</Typography>
          <List>
            {Object.keys(currentPermissions).length > 0 ? (
              Object.entries(currentPermissions).map(([uid, value]) => (
                <ListItem key={uid}>
                  <ListItemText primary={`Email: ${value.email}`} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveUserPermission(uid)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            ) : (
              <Typography variant="body2">Nenhum usuário com permissão ativa.</Typography>
            )}
          </List>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Usuários</InputLabel>
            <Select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)} fullWidth>
              {loadingUsers ? (
                <CircularProgress size={24} />
              ) : (
                availableUsers.map((user) => (
                  <MenuItem key={user.uid} value={user.uid}>
                    {user.nome} - {user.role}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">Cancelar</Button>
          <Button onClick={handleAddUserPermission} color="primary" disabled={!selectedUser}>
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TodosMunicipios;
