import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/config.js";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Container,
  CircularProgress,
  Typography,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd"; // Ícone para cadastro
import Footer from "./layout/Footer";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userCredentials, setUserCredentials] = useState({});
  const [error, setError] = useState("");
  const navigate = useNavigate();
  function handleCredentials(e) {
    setUserCredentials({ ...userCredentials, [e.target.name]: e.target.value });
  }

  function handleLogin(e) {
    e.preventDefault();
    setError("");
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (userCredentials.email && userCredentials.password) {
      if (!emailRegex.test(userCredentials.email)) {
        setError("O endereço de email informado é inválido");
      } else {
        setIsLoading(true);
        signInWithEmailAndPassword(
          auth,
          userCredentials.email,
          userCredentials.password
        )
          .then(() => {
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            const errorCode = error.code;
            switch (errorCode) {
              case "auth/user-not-found":
                setError("Usuário não encontrado!");
                break;
              case "auth/wrong-password":
                setError("A senha informada é inválida");
                break;
              case "auth/invalid-email":
                setError("O endereço de email informado é inválido");
                break;
              default:
                setError("Ocorreu um erro ao tentar fazer login");
                break;
            }
          });
      }
    } else {
      setError("Os campos não foram preenchidos corretamente.");
    }
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="img/logo.png" alt="Logo" width={"60%"} />
        <Typography component="h5" variant="h5">
          Gestão de Áreas
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Endereço de Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleCredentials}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleCredentials}
          />
          {error && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
          {isLoading ? (
            <CircularProgress sx={{ mt: 3, mb: 2 }} />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
            >
              Entrar
            </Button>
          )}
          <Grid container>
            <Grid item xs>
              <Link href="/recuperarsenha" style={{ textDecoration: "none" }}>
                Esqueceu a senha?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
