import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectMaps,
  fetchMaps,
  selectMapsStatus,
} from "../../../store/mapsSlices";
import {
  Paper,
  Grid,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Alert,
  Card,
  CardContent,
  Typography,
  CardActions,
  CardHeader,
  Avatar,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import LayersIcon from "@mui/icons-material/Layers";
import { ref, push, remove, update, get } from "firebase/database";
import { useNavigate, useParams } from "react-router-dom";
import { auth, realtimeDB } from "../../../firebase/config";
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const NucleoCard = ({ nucleo, onDelete, onEdit, onOpen }) => {
  return (
    <Card sx={{ maxWidth: 450, boxShadow: 3, borderRadius: 2 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#1976D2" }}>
            {nucleo.nome.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={
          <Typography variant="h7" fontWeight="bold">
            {nucleo.nome}
          </Typography>
        }
        subheader={nucleo.dataCriacao ? nucleo.dataCriacao : "N/A"}
      />
      <Divider />
      <CardContent>
        <Typography variant="body2" color="textSecondary">
          <strong>Criado por:</strong> {nucleo.criadoPor || "N/A"}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: "center" }}>
        <Button
          size="large"
          variant="outlined"
          color="primary"
          onClick={() => onOpen(nucleo.id)}
          sx={{ display: "flex", justifyContent: "center", width: 150 }}
        >
          <FolderOpenIcon fontSize="large" />
        </Button>

        <Button
          size="large"
          variant="outlined"
          color="warning"
          onClick={() => onEdit(nucleo)}
          sx={{ display: "flex", justifyContent: "center", width: 150 }}
        >
          <EditIcon fontSize="large" />
        </Button>

        <Button
          size="large"
          variant="outlined"
          color="error"
          onClick={() => onDelete(nucleo.id)}
          sx={{ display: "flex", justifyContent: "center", width: 150 }}
        >
          <DeleteIcon fontSize="large" />
        </Button>
      </CardActions>
    </Card>
  );
};

const MyMaps = () => {
  const { municipioId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const maps = useSelector(selectMaps);
  const mapsStatus = useSelector(selectMapsStatus);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [nucleoToDelete, setNucleoToDelete] = useState(null);
  const [nucleoName, setNucleoName] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingNucleo, setEditingNucleo] = useState(null);
  const [userCounts, setUserCounts] = useState([]);

  useEffect(() => {
    if (mapsStatus === "idle") {
      dispatch(fetchMaps(municipioId));
    }
  }, [mapsStatus, municipioId, dispatch]);

  useEffect(() => {
    const fetchUserCounts = async () => {
      if (maps.length > 0) {
        const userCountsData = await Promise.all(
          maps.map(async (nucleo) => {
            const usersRef = ref(realtimeDB, 'users/');
            const snapshot = await get(usersRef);
            const allUsers = snapshot.exists() ? snapshot.val() : [];
            const count = Object.values(allUsers).filter(
              (user) => user.municipioId === municipioId && user.nucleoId === nucleo.id
            ).length;
            return { nucleoNome: nucleo.nome, count };
          })
        );
        setUserCounts(userCountsData);
      }
    };
    fetchUserCounts();
  }, [maps, municipioId]);

  const pieData = {
    labels: userCounts.map((uc) => uc.nucleoNome),
    datasets: [
      {
        label: 'Beneficiários por Núcleo',
        data: userCounts.map((uc) => uc.count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setNucleoName("");
    setError("");
    setEditingNucleo(null);
    setOpenModal(false);
  };

  const handleNucleoNameChange = (e) => {
    setNucleoName(e.target.value);
  };

  const handleSaveNucleo = async () => {
    if (!nucleoName) {
      setError("O nome do núcleo é obrigatório.");
      return;
    }

    setIsSubmitting(true);
    const currentUserEmail = auth.currentUser.email;
    const currentDate = new Date().toLocaleString();

    try {
      if (editingNucleo) {
        const nucleoRef = ref(
          realtimeDB,
          `municipios/${municipioId}/nucleos/${editingNucleo.id}`
        );
        await update(nucleoRef, {
          nome: nucleoName,
          editadoPor: currentUserEmail,
          dataEdicao: currentDate,
        });
      } else {
        const nucleoRef = ref(realtimeDB, `municipios/${municipioId}/nucleos`);
        await push(nucleoRef, {
          nome: nucleoName,
          criadoPor: currentUserEmail,
          dataCriacao: currentDate,
        });
      }

      dispatch(fetchMaps(municipioId));
      handleCloseModal();
    } catch (error) {
      console.error("Erro ao cadastrar núcleo:", error);
      setError("Erro ao cadastrar o núcleo. Tente novamente.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditNucleo = (nucleo) => {
    setNucleoName(nucleo.nome);
    setEditingNucleo(nucleo);
    setOpenModal(true);
  };

  const handleDeleteClick = (nucleoId) => {
    setNucleoToDelete(nucleoId);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const nucleoRef = ref(
        realtimeDB,
        `municipios/${municipioId}/nucleos/${nucleoToDelete}`
      );
      await remove(nucleoRef);
      dispatch(fetchMaps(municipioId));
    } catch (error) {
      console.error("Erro ao apagar núcleo:", error);
    } finally {
      setOpenDeleteDialog(false);
      setNucleoToDelete(null);
    }
  };

  return (
    <>
      <Box sx={{ mt: 10, p: 3, display: 'flex', justifyContent: 'center' }}>
        <Paper
          elevation={4}
          sx={{
            width: '90%',
            maxWidth: 1500,
            p: 3,
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 4,
              justifyContent: "center",
            }}
          >
            <Typography sx={{ mb: 4 }} variant="h4" align="center" gutterBottom>
              <LayersIcon sx={{ mr: 1 }} /> Gerenciar Núcleos
            </Typography>
          </Box>

          {/* Gráfico de Pizza para exibir usuários por núcleo */}
          <Box sx={{ width: 400, margin: '0 auto', mb: 5 }}> {/* Ajuste o tamanho do gráfico aqui */}
            <Typography variant="h5" align="center" gutterBottom>
              Beneficiários por Núcleo
            </Typography>
            <Pie data={pieData} />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "start", mt: 4, mb: 4 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
            >
              Novo Núcleo
            </Button>
          </Box>

          {mapsStatus === "loading" ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <CircularProgress />
            </Box>
          ) : maps.length ? (
            <Grid container spacing={3}>
              {maps.map((map) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={map.id}>
                  <NucleoCard
                    nucleo={map}
                    onDelete={handleDeleteClick}
                    onEdit={handleEditNucleo}
                    onOpen={(nucleoId) => navigate(`/municipios/${municipioId}/nucleo/${nucleoId}`)}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center" style={{ padding: "10px" }}>
              <p>Você ainda não possui núcleos cadastrados.</p>
            </Grid>
          )}
        </Paper>
      </Box>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>{editingNucleo ? "Editar Núcleo" : "Cadastrar Novo Núcleo"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Nome do Núcleo"
            value={nucleoName}
            onChange={handleNucleoNameChange}
            fullWidth
            required
            error={!!error}
            helperText={error}
            sx={{ mt: 2 }}
          />
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleSaveNucleo}
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Salvando..." : editingNucleo ? "Salvar" : "Cadastrar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <Typography>Tem certeza que deseja apagar este núcleo?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Apagar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyMaps;
