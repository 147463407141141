import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getDatabase } from 'firebase/database'  // Importe o Realtime Database

// CONFIGURAÇÃO DE CREDENCIAIS DO FIREBASE
const firebaseConfig = {
  apiKey: "AIzaSyB584dYTQ9fAi2pnewYcEt2dLl6_suvlEw",
  authDomain: "reurbagora.firebaseapp.com",
  databaseURL: "https://reurbagora-default-rtdb.firebaseio.com",
  projectId: "reurbagora",
  storageBucket: "reurbagora.appspot.com",
  messagingSenderId: "808908136460",
  appId: "1:808908136460:web:b93a08d3e73990a5460e29",
  measurementId: "G-Z9LJ4RZBY7"
};

// CRIA AS VARIÁVEIS DE INICIALIZAÇÃO DO FIREBASE
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app)
export const realtimeDB = getDatabase(app) 
